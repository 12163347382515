/** @jsx jsx */
import { jsx } from 'theme-ui';
import * as React from 'react';
import { Link, Box, Flex } from 'rebass';
import { H3 } from '../components/Text';
import { PartnerLayout } from '../layouts/PartnerLayout';
import { Container } from '../components/Container';
import { useTranslation } from '../i18n/TranslationContext';
import sponsors from '../components/Sponsors';

interface PartnerProps {
  pageContext: {
    partners: {
      id: string;
      active: boolean;
      display_footer: string;
      size: string;
      logo: string;
      logoalt: string;
      title: string;
      url: string;
      link: string;
      src: string;
      tier: number;
    }[];
  };
}

const tierWrapper: {
  [key: string]: React.FC<{
    companyName: string;
    linkUrl: string;
    title: string;
  }>;
} = {
  '1': ({ children, title, linkUrl }) => (
    <Flex
      width="100%"
      sx={{ flexDirection: 'column', alignItems: 'center', flexWrap: 'wrap' }}
    >
      <Link href={linkUrl} target="_blank">
        {children}
      </Link>
      {title && (
        <Link
          href={linkUrl}
          sx={{
            textAlign: 'center',
            display: 'block',
          }}
          target="_blank"
        >
          {title}
        </Link>
      )}
    </Flex>
  ),
  '2': ({ children, companyName, title, linkUrl }) => (
    <Flex
      sx={{
        flexDirection: 'column',
        flexBasis: ['100%', `${(1 / 3) * 100}%`],
        alignItems: 'center',
      }}
    >
      <Link href={linkUrl} target="_blank">
        {children}
      </Link>
      {title && (
        <Link
          href={linkUrl}
          sx={{
            textAlign: 'center',
            display: 'block',
          }}
          target="_blank"
        >
          {title}
        </Link>
      )}
    </Flex>
  ),
  '3': ({ children, companyName, title, linkUrl }) => (
    <Flex
      sx={{
        flexDirection: 'column',
        flexBasis: ['50%', `${(1 / 4) * 100}%`],
        alignItems: 'center',
        mb: 4,
      }}
    >
      <Link href={linkUrl} target="_blank">
        {children}
      </Link>
      {title && (
        <Link
          href={linkUrl}
          sx={{
            textAlign: 'center',
            display: 'block',
          }}
          target="_blank"
        >
          {title}
        </Link>
      )}
    </Flex>
  ),
  '4': ({ children, companyName, title, linkUrl }) => (
    <Flex
      sx={{
        flexDirection: 'column',
        flexBasis: [`${(1 / 3) * 100}%`, `${(1 / 6) * 100}%`],
        mb: 3,
      }}
    >
      <Link href={linkUrl} target="_blank">
        {children}
      </Link>
    </Flex>
  ),
};

const tierSizes = [
  [425, 189],
  [350, 155],
  [250, 111],
  [200, 88],
];

const Partners: React.FC<PartnerProps> = ({ pageContext: { partners } }) => {
  const partnersByTier = partners.reduce(
    (acc, partner, i) => {
      if (partner.active && partner.src) {
        const { src, tier, link, url, title } = partner;
        const Wrapper = tierWrapper[tier] || tierWrapper['4'];

        const LogoSVG = sponsors[partner.logoalt];
        const image = (
          <Wrapper
            companyName={link}
            linkUrl={url}
            key={`partner_${i}`}
            title={title}
          >
            <LogoSVG
              sx={{
                fill: 'text',
                maxWidth: '100%',
              }}
              width={tierSizes[tier - 1][0]}
              height={tierSizes[tier - 1][1]}
            />
          </Wrapper>
        );

        const collection = acc[tier] || acc['4'];

        collection.push(image);
      }
      return acc;
    },
    {
      '1': [],
      '2': [],
      '3': [],
      '4': [],
    },
  );

  const t = useTranslation();

  return (
    <PartnerLayout>
      <Container>
        <Box mb={3}>
          <H3>{t('partners_title')}</H3>
        </Box>
        {partnersByTier['1'].length > 0 && (
          <Flex sx={{ mb: 4 }}>{partnersByTier['1']}</Flex>
        )}
        {partnersByTier['2'].length > 0 && (
          <Flex
            sx={{
              flexWrap: 'wrap',
              mb: 5,
              justifyContent: 'center',
            }}
          >
            {partnersByTier['2']}
          </Flex>
        )}
        {partnersByTier['3'].length > 0 && (
          <Flex
            sx={{
              flexWrap: 'wrap',
              mb: 5,
              justifyContent: 'center',
            }}
          >
            {partnersByTier['3']}
          </Flex>
        )}
        {partnersByTier['4'].length > 0 && (
          <Flex
            sx={{
              flexWrap: 'wrap',
            }}
          >
            {partnersByTier['4']}
          </Flex>
        )}
      </Container>
    </PartnerLayout>
  );
};

export default Partners;
