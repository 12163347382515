/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Box, Flex } from 'rebass';
import { LocalizedLink } from '../components/LocalizedLink';

import MainLayout from './MainLayout';
import { Container } from '../components/Container';
import { H2 } from '../components/Text';
import { useLocale } from '../i18n/LocaleContext';
import { useTranslation } from '../i18n/TranslationContext';

const partnersPath = '/partners';
const aboutLinks = [
  { path: `${partnersPath}`, label: { en: 'Our partners', fr: 'Partenaires' } },
  {
    path: `${partnersPath}/partner-with-us`,
    label: { en: 'Partner with us', fr: 'Devenir partenaire' },
  },
];

const activeStyle = { fontWeight: 'bold' };
export const PartnerLayout: React.FC = ({ children }) => {
  const { locale, basePath } = useLocale();
  const t = useTranslation();

  return (
    <MainLayout>
      <Container>
        <H2 sx={{ mb: 3 }} id="content">
          {t('partners_heading')}
        </H2>
        <Box
          sx={{
            pb: [3, 4],
            mb: [3, 4],
            borderBottom: 'solid 1px',
            borderColor: 'text',
          }}
        >
          <Flex
            sx={{
              mb: [3, 3, 0],
              flexWrap: 'wrap',
            }}
          >
            {aboutLinks.map(link => {
              return (
                <LocalizedLink
                  key={link.path}
                  sx={{
                    variant: 'variants.navlink',
                    mr: 3,
                    mb: 3,
                    ...(basePath === link.path
                      ? activeStyle
                      : { textDecoration: 'underline' }),
                  }}
                  to={link.path}
                >
                  {link.label[locale]}
                </LocalizedLink>
              );
            })}
          </Flex>
        </Box>
      </Container>
      {children}
    </MainLayout>
  );
};
